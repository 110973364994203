<template>
  <div>
    <!-- Parallax Header Section -->
    <v-parallax
      :lazy-src="headerImage.lazy"
      :src="headerImage.src"
      height="50vh"
      :sizes="_srcset.sizes"
      gradient="to top right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)"
      alt="MineClub"
    >
      <div class="d-flex flex-column fill-height justify-center align-center text-center">
        <h1 class="text-h4 font-weight-thin mb-4">
          <v-icon x-large :icon="mdiMinecraft" />
          <span>MineClub</span>
        </h1>
        <p class="white--text mb-2">
          MineClub er en dansk Minecraft server, som er under udvikling. Vi arbejder på at skabe en server, som er sjov for alle aldre.
        </p>
        <p class="white--text">Vi har en række spændende features, som vi glæder os til at præsentere for dig.</p>
      </div>
    </v-parallax>

    <!-- Features Section -->
    <v-container class="my-5">
      <v-row v-for="(feature, index) in features" :key="feature.title" align="center" class="mb-6">
        <v-col cols="12" md="6" :order="index % 2 === 0 ? 1 : 2">
          <v-img
            :src="getImageSrc(feature.image)"
            :lazy-src="getImageSrc(feature.image, true)"
            :sizes="getImageSizes(feature.image)"
            height="300px"
            class="feature-image mb-4 mb-md-0"
            alt="Feature Image"
          />
        </v-col>
        <v-col cols="12" md="6" :order="index % 2 === 0 ? 2 : 1">
          <v-card flat class="feature-card pa-4">
            <v-card-title class="headline">{{ feature.title }}</v-card-title>
            <v-card-text>{{ feature.description }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Join Us Section -->
    <v-parallax :src="getImageSrc('/img/joinus.png')" height="30vh" gradient="to top right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)">
      <div class="d-flex flex-column fill-height justify-center align-center text-center">
        <h2 class="text-h4 font-weight-thin mb-4">Join Os</h2>
        <p class="white--text">Bliv en del af vores fantastiske fællesskab i dag og begynd dit eventyr!</p>
        <v-btn color="primary" class="mt-4 elevation-5" @click="copyToClipboard">
          Join Nu
          <!--  <v-tooltip aria-label="Kopi" activator="parent" location="bottom">Klik for at kopiere <b>mc.mineclub.dk</b> til din clipboard</v-tooltip> -->
        </v-btn>
        <Teleport to="body">
          <v-snackbar v-model="showAlert" :timeout="2000" color="white">
            <v-icon :icon="mdiContentCopy" /> Kopieret til clipboard: mc.mineclub.dk

            <template #actions>
              <v-btn color="red" variant="text" @click="showAlert = false">Luk</v-btn>
            </template>
          </v-snackbar>
        </Teleport> 
      </div>
    </v-parallax> 

    <!-- FAQ Section -->
    <v-container class="my-5">
      <v-card>
        <v-card-title class="text-h4">Ofte Stillede Spørgsmål (FAQ)</v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
              <v-expansion-panel-title>
                <span>{{ faq.question }}</span>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ faq.answer }}
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-container> 

    <!-- Footer Section -->
    <TheFooter />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { mdiContentCopy, mdiMinecraft } from '@mdi/js';

const props = defineProps({
  height: {
    type: [Number, String],
    default: 500,
  },
  src: {
    type: String,
    default: '/img/parallaxfor.png',
  },
});

useHead({
  title: 'Forside',
});

const headerImage = {
  src: useImage()(props.src, { format: 'avif', quality: 70 }),
  lazy: useImage()(props.src, { format: 'avif', quality: 70 })
};

const _srcset = computed(() => {
  return useImage().getSizes(props.src, {
    sizes: 'xs:100vw sm:100vw md:100vw lg:100vw xl:100vw',
    modifiers: {
      format: 'avif',
      quality: 70,
      height: Number(props.height),
    },
  });
});

const showAlert = ref(false);
const features = ref([
  {
    title: 'Hub',
    description: 'Hubsne fungerer som et knudepunkt, hvor spillere ubesværet skifter mellem forskellige servere og lobby inden for netværket. Det er en sømløs gateway, der tilbyder mulighed for udforskning på tværs af forskellige servere og kategorier.',
    image: '/img/hub.png',
  },
  {
    title: 'Lobby',
    description: 'Lobbyen er stedet, hvor du kan samle mønter gennem forskellige metoder. Mønter kan bruges til mange ting, såsom at købe produkter på andre spilleres servere.',
    image: '/img/lobby.png',
  },
]);

const faqs = ref([
  {
    question: 'Hvordan tilslutter jeg mig serveren?',
    answer: 'Du kan tilslutte dig serveren ved at åbne Minecraft, vælge "Multiplayer" og derefter tilføje vores server IP-adresse.',
  },
  {
    question: 'Er der aldersgrænse for at spille på serveren?',
    answer: 'Nej, der er ingen aldersgrænse. Vi byder spillere i alle aldre velkommen.',
  },
  {
    question: 'Hvordan får man mønter?',
    answer: 'Du kan skaffe mønter ved at deltage i forskellige aktiviteter på serveren, såsom at sælge varer, deltage i events og meget mere.',
  },
  {
    question: 'Kan jeg spille med mine venner?',
    answer: 'Ja, du kan spille med dine venner ved at tilslutte jer samme server og spille sammen.',
  },
]);

function getImageSrc(src: string, lazy = false) {
  return useImage()(src, {
    format: 'avif',
    quality: 70,
    lazy,
  });
}

function getImageSizes(src: string) {
  return useImage().getSizes(src, {
    modifiers: {
      format: 'avif',
      quality: 70,
      height: 300,
    },
  }).sizes;
}

function copyToClipboard() {
  navigator.clipboard.writeText('mc.mineclub.dk');
  showAlert.value = true;
  setTimeout(() => {
    showAlert.value = false;
  }, 2000);
}
</script>

<style scoped>
.v-parallax {
  background-size: cover;
}

.feature-image {
  object-fit: cover;
  border-radius: 8px;
}

.feature-card {
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: scale(1.05);
}

.white--text {
  color: white !important;
}
</style>
